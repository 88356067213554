exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-hospitality-service-details-js": () => import("./../../../src/templates/hospitality-service-details.js" /* webpackChunkName: "component---src-templates-hospitality-service-details-js" */),
  "component---src-templates-hospitality-services-js": () => import("./../../../src/templates/hospitality-services.js" /* webpackChunkName: "component---src-templates-hospitality-services-js" */),
  "component---src-templates-hotel-operation-details-js": () => import("./../../../src/templates/hotel-operation-details.js" /* webpackChunkName: "component---src-templates-hotel-operation-details-js" */),
  "component---src-templates-hotel-operations-js": () => import("./../../../src/templates/hotel-operations.js" /* webpackChunkName: "component---src-templates-hotel-operations-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-inspiration-details-js": () => import("./../../../src/templates/inspiration-details.js" /* webpackChunkName: "component---src-templates-inspiration-details-js" */),
  "component---src-templates-inspirations-js": () => import("./../../../src/templates/inspirations.js" /* webpackChunkName: "component---src-templates-inspirations-js" */),
  "component---src-templates-our-services-js": () => import("./../../../src/templates/our-services.js" /* webpackChunkName: "component---src-templates-our-services-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-tags-template-js": () => import("./../../../src/templates/tags-template.js" /* webpackChunkName: "component---src-templates-tags-template-js" */),
  "component---src-templates-terms-of-use-js": () => import("./../../../src/templates/terms-of-use.js" /* webpackChunkName: "component---src-templates-terms-of-use-js" */),
  "component---src-templates-vendor-detail-js": () => import("./../../../src/templates/vendor-detail.js" /* webpackChunkName: "component---src-templates-vendor-detail-js" */)
}

